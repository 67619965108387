export enum Source {
  COCHA = 'cocha',
  ITAU = 'itau',
  RIPLEY = 'ripley',
  CENCOSUD = 'cencosud',
  TENPO = 'tenpo',
  BCI = 'bci',
  BICE = 'bice',
  BENEFICIOS = 'beneficios',
  JUMBOPRIME = 'jumboprime'
}