
import { LANDINGS_URL } from '../../../environments/environment';
import { Source } from "../enums/source.enum";
import { LandingConfiguration } from '../model/landing-configuration.model';

export const DEFAULT_LANDING_CONFIGURATION: LandingConfiguration & {
  meta: {
    title: string;
    description: string;
  }
} = {
  source: Source.COCHA,
  vertical: 'package',
  phone: '+562 2 464 13 00',
  linkLogo: 'https://www.cocha.com/',
  elements: [],
  meta: {
    title: 'Agencia de viajes con vuelos baratos, paquetes y más | COCHA',
    description: 'Descubre vuelos baratos, paquetes de viaje, hoteles y más para vivir la mejor experiencia de viaje. Ingresa ahora en COCHA viajes y encuentra las mejores ofertas.',
    settings: {
      showPreheader: true,
      showHeaderSimple: false,
      showPrincipal: true,
      showHeader: true,
      showSearchbox: true,
      showCyber: false,
    },
  },
  images: {
    bannerBackgroundColor: '#E2261C',
    bannerImage: `${LANDINGS_URL.landing_alliances_images}/cuotas/12-sin-interes.png'`,
    logoDesktop: '',
    logoMobile: '',
  },
  campaign: undefined,
};